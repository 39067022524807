import { filter } from 'rxjs-compat/operator/filter';
import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../../service';
import { Router } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { tap, map, first, take } from 'rxjs/operators';

@Component({
  selector: 'change-select-modal',
  templateUrl: './change-select-modal.component.html',
  styleUrls: ['./change-select-modal.component.scss'],
})
export class ChangeSelectModalComponent implements OnInit {
  queryCriterias = [];
  optionList = [];
  searchMessage;
  modalTitle;
  source;
  selectedOptions;
  codeToTranslate;
  codeList = [];
  inspectionEHS;
  issueStatus;
  changeTypeList;
  moduleList = [
    {
      code: 'incident',
      code_desc_zh: '事故管理'
    },
    {
      code: 'inspection',
      code_desc_zh: '检查管理'
    }
  ]
  constructor(
    public dataService: DataService,
    public appService: AppService,
    public router: Router,
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalController: ModalController,
    public localStorageService: LocalStorageService,
    private cd: ChangeDetectorRef,
  ) {
    let params = this.navParams.data;
    this.modalTitle = params.pageTitle;
    this.codeToTranslate = params.code;
    this.changeTypeList = params.changeTypeList;
    if (this.changeTypeList && this.codeToTranslate != 'module') {
      const list = this.changeTypeList.map(v => v.changeType)
      this.selectedOptions = list.join(',')
    }
    if (this.codeToTranslate != 'module') {
      this.getCodeList();
    } else {
      if (params.changeTypeList) {
        this.selectedOptions = params.changeTypeList
      }
      this.codeList = this.moduleList
    }

  }

  ngOnInit() {
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = '选择' + this.modalTitle;
  }

  getCodeList() {
    this.codeList = [];
    this.dataService.codesChildrenOf(this.codeToTranslate).pipe(
      map(res => (res['DATA'][0].childCodes)),
      tap((codes: Array<any>) => {
        this.codeList = codes
      }),
      take(1)
    ).subscribe()
  }

  markSelected(item) {
    if (this.selectedOptions && this.selectedOptions.split(',').includes(item.code)) {
      let temp = this.selectedOptions.split(',');
      temp.splice(temp.indexOf(item.code), 1);

      if (this.codeToTranslate != 'module') {
        this.codeList.forEach(v => {
          v.childCodes.forEach(c => {
            if (c.code == item.code) {
              if (temp.find(b => b == v.code)) {
                temp.splice(temp.indexOf(v.code), 1);
              }
            }
          })
        })
        if (item.code == '_SYS_CT_1') {
          const list = this.codeList.filter(v => v.code == '_SYS_CT_1')
          list[0].childCodes.forEach(c => {
            temp.forEach(v => {
              if (c.code == v) {
                temp = temp.filter(b => b != c.code)
              }
            })
          })

        }
        if (item.code == '_SYS_CT_2') {
          const list = this.codeList.filter(v => v.code == '_SYS_CT_2')
          list[0].childCodes.forEach(c => {
            temp.forEach(v => {
              if (c.code == v) {
                temp = temp.filter(b => b != c.code)
              }
            })
          })

        }
        if (item.code == '_SYS_CT_3') {
          const list = this.codeList.filter(v => v.code == '_SYS_CT_3')
          list[0].childCodes.forEach(c => {
            temp.forEach(v => {
              if (c.code == v) {
                temp = temp.filter(b => b != c.code)
              }
            })
          })

        }
      }



      this.selectedOptions = temp.join()
    } else {
      if (this.selectedOptions) {
        const temp = this.selectedOptions.split(',');
        temp.push(item.code);
        this.selectedOptions = temp.join();
      } else {
        this.selectedOptions = item.code;
      }
      if (this.codeToTranslate != 'module') {
        if (item.code == '_SYS_CT_1') {
          const temp = this.selectedOptions.split(',');
          this.codeList.forEach(v => {
            if (v.code == '_SYS_CT_1') {
              v.childCodes.forEach(c => {
                temp.push(c.code)
              })
            }
          })
          this.selectedOptions = temp.join()
        }
        if (item.code == '_SYS_CT_2') {
          const temp = this.selectedOptions.split(',');
          this.codeList.forEach(v => {
            if (v.code == '_SYS_CT_2') {
              v.childCodes.forEach(c => {
                temp.push(c.code)
              })
            }
          })
          this.selectedOptions = temp.join()
        }
        if (item.code == '_SYS_CT_3') {
          const temp = this.selectedOptions.split(',');
          this.codeList.forEach(v => {
            if (v.code == '_SYS_CT_3') {
              v.childCodes.forEach(c => {
                temp.push(c.code)
              })
            }
          })
          this.selectedOptions = temp.join()
        }
      }
    }

  }

  isSelected(item) {
    if (this.selectedOptions && this.selectedOptions.split(',').includes(item.code)) {
      return true;
    }
    return false;
  }

  cancel() {
    this.modalController.dismiss();
  }

  confirm() {
    const selectedOptions = []
    if (this.codeToTranslate != 'module') {
      const list = this.selectedOptions.split(',')
      this.codeList.forEach(v => {
        list.forEach(c => {
          if (v.code == c) {
            selectedOptions.push({
              changeType: c,
              parentChangeType: v.code,
              code_desc_zh: v.code_desc_zh
            })
          }
          v.childCodes.forEach(b => {
            if (b.code == c) {
              selectedOptions.push({
                changeType: b.code,
                parentChangeType: v.code,
                code_desc_zh: b.code_desc_zh
              })
            }
          })
        })
      })
    }
    this.modalController.dismiss({ selectedOptions: this.codeToTranslate != 'module' ? selectedOptions : this.selectedOptions });
  }
}
