import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { hashConfig } from './config/hashConfig';

const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'tabs', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'home', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'tabs/home', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'incident-reporting', loadChildren: './incident-reporting/incident-reporting.module#IncidentReportingModule' },
  { path: 'hidden-reporting', loadChildren: './hidden-danger-reporting/hidden-danger-reporting.module#HiddenDangerReportingModule'},
  { path: 'immediate-inspection', loadChildren: './immediate-inspection/immediate-inspection.module#ImmediateInspectionModule'},
  { path: 'immediate-change', loadChildren: './immediate-change/immediate-change.module#ImmediateChangeModule'},
  { path: 'incident-corrective-execution', loadChildren: './incident-corrective-execution/incident-corrective-execution.module#IncidentCorrectiveExecutionModule' },
  { path: 'incident-corrective-verification', loadChildren: './incident-corrective-verification/incident-corrective-verification.module#IncidentCorrectiveVerificationModule' },
  { path: 'announcement', loadChildren: './announcement/announcement.module#AnnouncementPageModule' },
  { path: 'announcement-detail', loadChildren: './announcement-detail/announcement-detail.module#AnnouncementDetailPageModule' },
  { path: 'message-center', loadChildren: './message-center/message-center.module#MessageCenterPageModule' },
  { path: 'message-detail', loadChildren: './message-detail/message-detail.module#MessageDetailPageModule' },
  { path: 'setting-host', loadChildren: './setting-host/setting-host.module#SettingHostPageModule' },
  { path: 'add-inspection-object', loadChildren: './add-inspection-object/add-inspection-object.module#AddInspectionObjectPageModule' },
  { path: 'add-casulty', loadChildren: './add-casulty/add-casulty.module#AddCasultyModule' },
  { path: 'add-investigation', loadChildren: './add-investigation/add-investigation.module#AddInvestigationModule' },
  { path: 'task-center', loadChildren: './task-center/task-center.module#TaskCenterPageModule' },
  { path: 'task-detail-best', loadChildren: './task-detail-best/task-detail-best.module#TaskDetailBestPageModule' },
  { path: 'task-detail', loadChildren: './task-detail/task-detail.module#TaskDetailPageModule' },
  { path: 'task-detail-checklist', loadChildren: './task-detail-checklist/task-detail-checklist.module#TaskDetailChecklistPageModule' },
  { path: 'task-detail-object-modal', loadChildren: './task-detail-object-modal/task-detail-object-modal.module#TaskDetailObjectModule'},
  { path: 'task-no-schedule', loadChildren: './task-no-schedule/task-no-schedule.module#TaskNoSchedulePageModule' },
  { path: 'casualty', loadChildren: './casualty/casualty.module#CasualtyPageModule' },
  { path: 'investigation-group', loadChildren: './investigation-group/investigation-group.module#InvestigationGroupPageModule' },
  { path: 'corrective-action-detail', loadChildren: './corrective-action-detail/corrective-action-detail.module#CorrectiveActionDetailPageModule' },
  { path: 'corrective-action-history', loadChildren: './corrective-action-history/corrective-action-history.module#CorrectiveActionHistoryModule' },
  { path: 'hidden-detail', loadChildren: './hidden-detail/hidden-detail.module#HiddenDetailPageModule' },
  { path: 'input-research-report', loadChildren: './input-research-report/input-research-report.module#InputResearchReportModule' },
  { path: 'inspection-detail', loadChildren: './inspection-detail/inspection-detail.module#InspectionDetailPageModule' },
  { path: 'incident-corrective-detail', loadChildren: './incident-corrective-detail/incident-corrective-detail.module#IncidentCorrectiveDetailPageModule' },
  { path: 'incident-center', loadChildren: './incident-center/incident-center.module#IncidentCenterPageModule' },
  { path: 'incident-detail-single', loadChildren: './incident-detail-single/incident-detail-single.module#IncidentDetailSinglePageModule' },
  { path: 'inspection-form', loadChildren: './inspection-form/inspection-form.module#InspectionFormPageModule' },
  { path: 'report-center', loadChildren: './report-center/report-center.module#ReportCenterPageModule' },
  { path: 'report-summary', loadChildren: './report-summary/report-summary.module#ReportSummaryPageModule' },
  { path: 'report-detail', loadChildren: './report-detail/report-detail.module#ReportDetailPageModule' },
  { path: 'pending-task-detail', loadChildren: './pending-task-detail/pending-task-detail.module#PendingTaskDetailModule' },
  { path: 'draft-redo', loadChildren: './incident-draft-redo/incident-draft-redo.module#IncidentDraftRedoModule' },
  { path: 'message-detail', loadChildren: './message-detail/message-detail.module#MessageDetailPageModule' },
  { path: 'record-inspection', loadChildren: './record-inspection/record-inspection.module#RecordInspectionPageModule' },
  { path: 'record-change', loadChildren: './record-change/record-change.module#RecordChangePageModule' },
  { path: 'record-corrective', loadChildren: './record-corrective/record-corrective.module#RecordCorrectivePageModule' },
  { path: 'record-corrective-change', loadChildren: './record-corrective-change/record-corrective-change.module#RecordCorrectiveChangePageModule' },
  { path: 'record-evaluation', loadChildren: './record-evaluation/record-evaluation.module#RecordEvaluationModule' },
  { path: 'record-accident', loadChildren: './record-accident/record-accident.module#RecordAccidentPageModule' },
  { path: 'record-injuryed', loadChildren: './record-injuryed/record-injuryed.module#RecordInjuryedPageModule' },
  { path: 'record-accident-corrective', loadChildren: './record-accident-corrective/record-accident-corrective.module#RecordAccidentCorrectivePageModule' },
  { path: 'record-hidden-danger', loadChildren: './record-hidden-danger/record-hidden-danger.module#RecordHiddenDangerPageModule' },
  { path: 'record-detail', loadChildren: './record-detail/record-detail.module#RecordDetailPageModule' },
  { path: 'report-personal', loadChildren: './report-personal/report-personal.module#ReportPersonalPageModule' },
  { path: 'report-check-table', loadChildren: './report-check-table/report-check-table.module#ReportCheckTablePageModule' },
  { path: 'report-training', loadChildren: './report-training/report-training.module#ReportTrainingPageModule' },
  { path: 'task-schedule-info', loadChildren: './task-schedule-info/task-schedule-info.module#TaskScheduleInfoPageModule' },
  { path: 'task-correct-history', loadChildren: './task-correct-history/task-correct-history.module#TaskCorrectHistoryPageModule' },
  { path: 'task-correct-info', loadChildren: './task-correct-info/task-correct-info.module#TaskCorrectInfoPageModule' },
  { path: 'task-detail-basic', loadChildren: './task-detail-basic/task-detail-basic.module#TaskDetailBasicPageModule' },
  { path: 'task-deviation-info', loadChildren: './task-deviation-info/task-deviation-info.module#TaskDeviationInfoPageModule' },
  { path: 'report-hidden-danger', loadChildren: './report-hidden-danger/report-hidden-danger.module#ReportHiddenDangerPageModule' },
  { path: 'report-safety-inspection', loadChildren: './report-safety-inspection/report-safety-inspection.module#ReportSafetyInspectionPageModule' },
  { path: 'schedule', loadChildren: './schedule/schedule.module#ScheduleModule' }, // 日程安排
  { path: 'live-newsletter', loadChildren: './live-newsletter/live-newsletter.module#LiveNewsletterModule' }, // 现场快报
  { path: 'my-training', loadChildren: './my-training/my-training.module#MyTrainingModule' }, // 我的培训
  { path: 'my-training-detail', loadChildren: './my-training-detail/my-training-detail.module#MyTrainingDetailModule' }, // 课程详情
  { path: 'live-copy', loadChildren: './live-newsletter-copy copy/live-newsletter-copy.module#LiveNewsletterCopyModule' },
  { path: 'verify-history', loadChildren: './perform-validation-history copy/perform-validation-history.module#PerformValidationHistoryModule' },
  { path: 'taskchk-assign-page', loadChildren: './taskchk-assign-page/taskchk-assign-page.module#TaskchkAssignPageModule' }, // 整改责任措施
  { path: 'app-login', loadChildren: './app-login/app-login.module#AppLoginModule' }, // 钉钉登录
  { path: 'task-zxzg', loadChildren: './task-zxzg/task-zxzg.module#TaskZxzgModule' }, // 百世执行整改
  { path: 'task-yzzg', loadChildren: './task-yzzg/task-yzzg.module#TaskYzzgModule' }, // 百世验证整改
  { path: 'incident-newsletter', loadChildren: './incident-newsletter/incident-newsletter.module#IncidentNewsletterModule' }, // 新建事故快报
  { path: 'task-zxzg-incident', loadChildren: './task-zxzg-incident/task-zxzg-incident.module#TaskZxzgIncidentModule' }, // 百世执行整改
  { path: 'task-yzzg-incident', loadChildren: './task-yzzg-incident/task-yzzg-incident.module#TaskYzzgIncidentModule' }, // 百世验证整改
  { path: 'mission-detail-page/:id', loadChildren: './pages/mission-detail-page/mission-detail-page.module#MissionDetailPagePageModule' },// 任务详情
  { path: 'inspection-forms', loadChildren: './immediate-inspection/inspection-form/inspection-form.module#InspectionFormModule' },
  { path: 'change-forms', loadChildren: './immediate-change/change-form/change-form.module#ChangeFormModule' },
  { path: 'incident-reporting-preview', loadChildren: './incident-reporting-preview/incident-reporting-preview.module#IncidentReportingPreviewModule' }, // 提交事故快报预览
  { path: 'tab-calendar',loadChildren:'./tab-calendar/tab-calendar.module#TabCalendarPageModule' },
  { path: 'add-injured-person', loadChildren: './add-injured-person/add-injured-person.module#AddInjuredPersonModule' },
  { path: 'add-corrective-actions', loadChildren: './pages/corrective-actions-page/corrective-actions-page.module#CorrectiveActionsPageModule' },
  { path: 'add-finding-page', loadChildren: './immediate-inspection/inspection-form/add-finding-page/add-finding-page.module#AddFindingPageModule' },
  { path: 'change-add-finding-page', loadChildren: './immediate-change/change-form/add-finding-page/add-finding-page.module#AddFindingPageModule' },
  { path: 'add-action-page', loadChildren: './immediate-inspection/inspection-form/add-action-page/add-action-page.module#AddActionPageModule' },
  { path: 'e-learning', loadChildren: './e-learning/e-learning.module#ELearningPageModule' },
  { path: 'record-plan-inspection', loadChildren: './record-plan-inspection/record-plan-inspection.module#RecordPlanInspectionPageModule' },
  { path: 'task-details-page/:id', loadChildren: './customer-inspection/task-details-page/task-details-page.module#TaskDetailPageModule' },// 客户检查待认领任务详情
  { path: 'customer-space-form/:id', loadChildren: './customer-inspection/customer-space-form/customer-space-form.module#CustomerSpaceFormPageModule' },// 客户检查待认领任务详情
  // { path: 'customer-inspection-detail/:id', loadChildren: './customer-inspection/customer-inspection-detail/customer-inspection-detail.module#CustomerInspectionPageModule' },// 客户检查待办任务详情
  { path: 'inspection-turn-to-send', loadChildren: './customer-inspection/task-details-page/turn-to-send/turn-to-send.module#TurnToSendPageModule' },



  { path: 'turn-to-send', loadChildren: './pages/mission-detail-page/turn-to-send/turn-to-send.module#TurnToSendPageModule' },
  // 环境服务
  { path: 'environment-services', loadChildren: './environment-services/environment-services.module#EnvironmentServicesModule' },
  // 微信公众号显示满意度
  
  // 运送服务
  { path: 'shipping-services', loadChildren: './shipping-services/shipping-services.module#ShippingServicesModule' },
  // 空间检查
  { path: 'space-inspection-list', loadChildren: './record-space-list/record-space.module#RecordSpaceModule' },
  // 患者满意度调查
  { path: 'satisfaction-survey-list', loadChildren: './record-satisfaction-survey/record-satisfaction-survey.module#RecordSatisfactionSurveyModule' },
  // 岗位评估-上报
  { path: 'jobs-evaluation', loadChildren: './jobs-evaluation/jobs-evaluation.module#JobsEvaluationModule' },
  // 岗位评估-记录
  { path: 'post-evaluation-list', loadChildren: './record-post-evaluation/record-post-evaluation.module#RecordPostEvaluationModule' },
  // 设施管理
  { path: 'facility-management', loadChildren: './facility-management/facility-management.module#FacilityManagementModule' },
  // 安保服务
  { path: 'security-services', loadChildren: './security-services/security-services.module#SecurityServicesModule' },
  { path: 'most-loss-list', loadChildren: './most-loss-list/most-loss-list.module#MostLossListComponentModule'},
  { path: 'assessment-form-detail', loadChildren: './assessment-detail/assessment-detail.module#AssessmentDetailComponentModule'},
  { path: 'assessment-org-detail', loadChildren: './assessment-org-detail/assessment-org-detail.module#AssessmentOrgDetailComponentModule'},
  { path: 'assessment-ranking-list', loadChildren: './assessment-ranking/assessment-ranking.module#AssessmentRankingComponentModule'},
  { path: 'most-loss-item', loadChildren: './most-loss-item/most-loss-item.module#MostLossItemComponentModule' },
  { path: 'reloss-list', loadChildren: './reloss-list/reloss-list.module#ReLossListComponentModule' },
  { path: 'loss-detail', loadChildren: './loss-detail/loss-detail.module#LossDetailComponentModule' },
  { path: 'assessment-area-detail', loadChildren: './assessment-area-detail/assessment-area-detail.module#AssessmentAreaDetailComponentModule'},
  { path: 'pic-slides', loadChildren: './pic-slides/pic-slides.module#PictureSlidesComponentModule' },
  { path: 'pic-single', loadChildren: './pic-single/pic-single.module#PictureSingleComponentModule' },
  { path: 'view-all-picture', loadChildren: './shared/components/view-all-picture/view-all-picture.module#ViewAllPictureModule' },
  { path: 'project-evaluation', loadChildren: './project-evaluation/project-evaluation.module#ProjectEvaluationModule' },
  // 合规文件上传
  { path: 'upload-compliance-documents', loadChildren: './upload-compliance-documents/upload-compliance-documents.module#UploadComplianceDocumentsModule' },
  { path: 'pic-slides', loadChildren: './pic-slides/pic-slides.module#PictureSlidesComponentModule' },
  { path: 'preview-file', loadChildren: './shared/components/preview-file/preview-file.module#PreviewFileModule' },
  // ase整改
  { path: 'record-ase-corrective', loadChildren: './record-ase-corrective/record-ase-corrective.module#RecordASECorrectivePageModule' },
  { path: 'record-ase-upload', loadChildren: './record-ase-upload/record-ase-upload.module#RecordAseUploadPageModule' },
  
  // qpe整改
  { path: 'record-qpe-corrective', loadChildren: './record-qpe-corrective/record-qpe-corrective.module#RecordQPECorrectivePageModule' },
  // 项目自评整改
  { path: 'record-evaluation-corrective', loadChildren: './record-evaluation-corrective/record-evaluation-corrective.module#RecordEvaluationCorrectivePageModule' },
  { path: 'self-evaluation-analysis', loadChildren: './self-eva-analysis/self-eva-analysis.module#SelfEvaludationAnalysisModule'},
  { path: 'self-evaluation-rank', loadChildren: './self-eva-rank/self-eva-rank.module#SelfEvaludationRankModule'},
  { path: 'self-evaluation-org', loadChildren: './self-eva-org/self-eva-org.module#SelfEvaludationOrgModule'},
  { path: 'self-evaluation-loss-list', loadChildren: './self-eva-loss-list/self-eva-loss-list.module#SelfEvaluationLossListComponentModule'},
  { path: 'certified-exam', loadChildren: './certified-exam/certified-exam.module#CertifiedExamModule' },
  { path: 'observer-list', loadChildren: './observer-list/observer-list.module#ObserverListModule' },
  { path: 'observer-exam', loadChildren: './observer-exam/observer-exam.module#ObserverExamModule' },
  { path: 'self-evaluation-loss-item', loadChildren: './self-eva-loss-item/self-eva-loss-item.module#SelfEvaluationLossItemComponentModule'},
  { path: 'self-evaluation-loss-detail', loadChildren: './self-eva-loss-detail/self-eva-loss-detail.module#SelfEvaluationLossDetailComponentModule'},
  // 环境服务
  { path: 'evs-report', loadChildren: './evs-report/evs-report.module#EvsReportPageModule' },
  { path: 'evs-report-month', loadChildren: './evs-report-month/evs-report-month.module#EvsReportMonthPageModule' },
  { path: 'self-evaluation-loss-item', loadChildren: './self-eva-loss-item/self-eva-loss-item.module#SelfEvaluationLossItemComponentModule'},

  { path: 'certified-exam', loadChildren: './certified-exam/certified-exam.module#CertifiedExamModule' },
  { path: 'observer-list', loadChildren: './observer-list/observer-list.module#ObserverListModule' },
  { path: 'observer-exam', loadChildren: './observer-exam/observer-exam.module#ObserverExamModule' },
  // 微信个人中心
  { path: 'wx-personal-center', loadChildren: './wx-personal-center/wx-personal-center.module#WxPersonalCenterComponentModule' },
  // 管理者看板EHS
  { path: 'admin-ehs', loadChildren: './admin-ehs/admin-ehs.module#AdminEHSPageModule' },
  // 医护满意度qrcode
  { path: 'generate-qrcode', loadChildren: './generate-qrcode/generate-qrcode.module#GenerateQRCodePageModule' },
  // 项目报告
  { path: 'project-report', loadChildren: './project-reports/project-reports.module#ProjectReportsModule' },
  // 微信公众号
  { path: 'wx-official-account-login', loadChildren: './wx-official-account-login/wx-official-account-login.module#WxOfficialAccountLoginModule' }, // 钉钉登录
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true }),

    // RouterModule.forRoot(routes, { useHash: false,preloadingStrategy: PreloadAllModules })

  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
