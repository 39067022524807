import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges, ChangeDetectorRef  } from '@angular/core';
import { ModalController, NavController, PopoverController } from '@ionic/angular';
import { DataService } from '../../../service/data.service';
import { AppService } from '../../../service/app.service';
import { PreviewPictureComponent } from '../preview-picture/preview-picture.component';
import { PopoverImageComponent } from '../popover-image/popover-image.component';
import { NativeService } from 'src/app/providers/NativeService';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { LocalStorageService } from 'src/app/service';
import { ActionSheetController } from '@ionic/angular';
import { Router } from '@angular/router';
declare var wx: any;
@Component({
	selector: 'app-select-picture-new',
	templateUrl: './select-picture-new.component.html',
	styleUrls: ['./select-picture-new.component.scss'],
})
export class SelectPictureNewComponent implements OnInit, OnChanges {
	@Input() fieldIds: any; // 文件id字符串
	@Input() editable: boolean; // 是否为可编辑的文件显示
	@Input() fileDTOs: any;
	@Input() isCamera: boolean;

	// 是否可以选择图片来源，可用选项为CAMERA_ONLY（只使用相机）和BOTH（用户选择相机或图库）
	@Input() sourceMode: string = 'CAMERA_ONLY';

	// 是否保存到相册
	@Input() saveToPhotoAlbum = false;
	// 0 相册； Choose image from the device's photo library (same as SAVEDPHOTOALBUM for Android)
	// 1 相机； Take picture from camera
	// 2 相册; Choose image only from the device's Camera Roll album (same as PHOTOLIBRARY for Android)
	@Input() pictureSourceType = 0;

	@Input() setUp: any;
	@Output() fileObjListChange = new EventEmitter<any>(); // 事件返回文件
	@ViewChild('picker') picker: ElementRef; // 上传文件的输入框
	@ViewChild('picker1') picker1: ElementRef; // 上传文件的输入框

	localData;
	files: any[] = []; // 图片数组
	android = false;
	isH5 = JSON.parse(this.localStorageService.getStore('isH5'));
	isTest = JSON.parse(this.localStorageService.getStore('isTest'));
	loading;

	constructor(
		private modalController: ModalController,
		private dataService: DataService,
		private appService: AppService,
		private nativeService: NativeService,
		private camera: Camera,
		public localStorageService: LocalStorageService,
		private changeDetectorRef: ChangeDetectorRef,
		private actionSheetController: ActionSheetController,
		private router: Router,
		private popoverController: PopoverController,
		private navController: NavController
	) { }

	ngOnInit() {
		// 下载文件
		this.downloadFile();
		if (!this.editable && this.fileDTOs) {
			this.files = this.fileDTOs;
			// console.log(this.files);
		}
		
		this.android = this.nativeService.isAndroid();
		// console.log('附件上传判断', this.nativeService.isAndroid(), this.nativeService.isIos(), document.getElementById('filefieldIds'));
		// var obj1 = document.getElementById('file');
		// if(this.nativeService.isAndroid()) {
		// 	obj1.setAttribute('capture','camera');
		// } else {
		// 	obj1.removeAttribute('capture');
		// }
		// var plateform = Zepto.device.os;
		// if(plateform == "android"){
		// 	$("selector").find("input[type='file']").attr("capture","camera");
		// }else if(plateform=="ios"){
		// 	$("selector").find("input[type='file']").removeAttr("capture");
		// }
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.ngOnInit();
	}

	// 获取图片dto
	getFileDTOs() {
		return this.files;
	}

	// 选择并上传图片
	uploadFile() {
		this.loading = true;
		const files = this.picker.nativeElement.files;
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append('file', files[i]);
			// formData.append('name', name);
			this.dataService.uploadFile(formData).subscribe(res => {
				if (!res.DATA) {
					this.loading = false;
					this.appService.toastTip(res.MSG, false);
				} else {
					const data = res.DATA;
					this.downloadSingleFile(data.id).then(resOut => {
						this.loading = false;
						let fieldIds = '';
						this.files.forEach((element, index) => {
							if (index !== this.files.length - 1) {
								fieldIds += element.id + ',';
							} else {
								fieldIds += element.id;
							}
						});
						this.fileObjListChange.emit(fieldIds);
					});
				}
			}, (err) => {
				this.loading = false;
				this.appService.toastTip('get_data_failed_key', true);
			});
		}
	}

	// 查看图片
	async viewerPicture(event, index, file) {
		history.pushState(null, null, document.URL);
		// window.open(file.path, 'Download');
		const urls = [];
		this.files.forEach(file => {
	    	urls.push(file.path);
		})
		// this.router.navigate(['pic-single'], { queryParams: {
		//   url: file.path,
		// }});
		// event.stopPropagation();
		
		const popover = await this.popoverController.create({
		component: PopoverImageComponent,
		componentProps: {
			path: file.path,
			type: 'single',
		},
		showBackdrop: true,
		backdropDismiss: true
		});
		popover.onDidDismiss().then(res => {

		});
		return await popover.present();
	}

	async userDownloadFile(event, index, file) {
		history.pushState(null, null, document.URL);
		const urls = [];
		this.files.forEach(file => {
		   urls.push(file.path);
		})
		// this.router.navigate(['pic-slides'], { queryParams: {
		//   initialIndex: index,
		//   urls: JSON.stringify(urls)
		// }});
		// event.stopPropagation();

		const popover = await this.popoverController.create({
		component: PopoverImageComponent,
		componentProps: {
			path: file.path,
			// urls: JSON.stringify(urls),
			type: 'single'
		},
		showBackdrop: true,
		backdropDismiss: true
		});
		popover.onDidDismiss().then(res => {

		});
		return await popover.present();
	}

	// 移除选取的图片
	removePicture(index) {
		this.files = this.files.filter((res, i) => index !== i);
		let fieldIds = '';
		this.files.forEach((res, i) => {
			if (i !== this.files.length - 1) {
				fieldIds += res.id + ',';
			} else {
				fieldIds += res.id;
			}
		});
		this.fileObjListChange.emit(fieldIds);
		this.changeDetectorRef.detectChanges();
	}

	clearAllPictures() {
		this.files = [];
		this.fileObjListChange.emit('');
	}

	// 下载文件
	async downloadFile() {
		if (this.fieldIds) {
			let fileIdArr = this.fieldIds.split(',');
			for (let item of fileIdArr) {
				if (item) {
					await this.downloadSingleFile(item);
				}
			}
		}
	}

	// 判断文件是不是图片
	isPicSuffix(str) {
    const strUpper = str.toString().toUpperCase();
		if (strUpper.indexOf('JPG') > -1 || strUpper.indexOf('PNG') > -1 || strUpper.indexOf('GIF') > -1 ||
    strUpper.indexOf('JPEG') > -1 || strUpper.indexOf('SVG') > -1) {
			return true
		}
		return false;
	}

	async downloadSingleFile(element): Promise<any> {
		let promise = new Promise<void>((resolve, reject) => {
			this.dataService.getFile(element).subscribe(res => {
				if (res.DATA) {
					const tmpFile = {
						id: res.DATA.id,
						download: res.DATA.path,
						path: res.DATA.path,
						originalName: res.DATA.originalName,
						type: res.DATA.type
					};
					if (this.files.filter(e => res.DATA.id === e.id).length === 0) {
						this.files.push(tmpFile);
					}
					resolve();
				} else {
					this.appService.toastTip('附件下载失败', false);
					reject();
				}
			}, (e) => console.log(e));
		}).catch((err) => {
			this.appService.toastTip('附件下载失败', false)
		});
		return promise;
	}

	isFilePicture(previewPath: string) {
		// console.log(previewPath, previewPath[0]);
		return previewPath && previewPath[0] !== '/';
	}

	getFullPreviewPath(previewPath) {
		return document.location.origin + previewPath;
	}

	async chooseImageAndroid() {
		switch (this.sourceMode) {
			case 'BOTH':
			const actionSheet = await this.actionSheetController.create({
				header: "选择图片来源",
				buttons: [{
					text: '从图库中加载',
					cssClass: 'image-selection-text',
					handler: () => {
						this.androidFile(this.camera.PictureSourceType.PHOTOLIBRARY);
					}
				},
				{
					text: '使用相机',
					cssClass: 'image-selection-text',
					handler: () => {
						this.androidFile(this.camera.PictureSourceType.CAMERA);
					}
				},
				{
					text: '取消',
					cssClass: 'image-selection-text',
					role: 'cancel'
				}
				]
			});
			await actionSheet.present();
			break;

			case 'CAMERA_ONLY':
			this.androidFile(this.camera.PictureSourceType.CAMERA);
			break;
		}
	}

	androidFile(sourceType) {
		let that = this;
		// console.log('点击了');
		// var sourceType = that.camera.PictureSourceType.CAMERA
		// if (this.pictureSourceType === 0){
		// 	sourceType = that.camera.PictureSourceType.PHOTOLIBRARY
		// }else if (this.pictureSourceType === 1){
		// 	sourceType = that.camera.PictureSourceType.CAMERA
		// }else if (this.pictureSourceType === 2){
		// 	sourceType = that.camera.PictureSourceType.SAVEDPHOTOALBUM
		// }
		const options: CameraOptions = {
			quality: 50,
			destinationType: that.camera.DestinationType.DATA_URL,
			encodingType: that.camera.EncodingType.JPEG,
			mediaType: that.camera.MediaType.PICTURE,
			targetWidth: 720,
			saveToPhotoAlbum: false,
			sourceType: sourceType
		};
		that.loading = true;
		that.camera.getPicture(options).then((imageData) => {
			const formData = new FormData();
			formData.append('file', imageData);
			// let base64Image = 'data:image/jpeg;base64,' + imageData;
			that.dataService.uploadFileApp(formData).subscribe(res => {
				if (!res.DATA) {
					that.loading = false;
					that.appService.toastTip(res.MSG, false);
				} else {
					const data = res.DATA;
					that.downloadSingleFile(data.id).then(resOut => {
						that.loading = false;
						let fieldIds = '';
						that.files.forEach((element, index) => {
							if (index !== that.files.length - 1) {
								fieldIds += element.id + ',';
							} else {
								fieldIds += element.id;
							}
						});
						that.fileObjListChange.emit(fieldIds);
						that.changeDetectorRef.detectChanges();
					});
				}
			}, (err) => {
				that.loading = false;
				that.appService.toastTip('get_data_failed_key', true);
			});
		}, (err) => {
			that.loading = false;
		});
	}


	chooseImage() {
		const that = this;
		let sourceType = ['album', 'camera'];
		if(that.setUp && that.setUp.length) {
			sourceType = that.setUp;
		}
		wx.ready(function () {
			wx.chooseImage({
				count: 1, // 默认9
				sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有'original',
				sourceType: sourceType, // 可以指定来源是相册还是相机，默认二者都有
				defaultCameraMode: "normal", //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
				isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
				success: function (res) {
					var localIds = res.localIds; // 返回选定照片的本地ID列表，
					const formData = new FormData();
					that.loading = true;
					wx.getLocalImgData({
						localId: localIds[0],
						success: function (result) {
							var localData = result.localData; // localData是图片的base64数据，可以用img标签显示
							// if (localData.indexOf('data:image') != 0) {
							// 	//判断是否有这样的头部
							// 	localData = 'data:image/jpeg;base64,' +  localData
							// }
							// localData = localData.replace(/\r|\n/g, '').replace('data:image/jgp', 'data:image/jpeg')

							if(this.android) {
								that.localData = localData.slice(22);
							} else {
								that.localData = localData.slice(22);
							}
							//第一个替换的是换行符，第二个替换的是图片类型，因为在IOS机上测试时看到它的图片类型时jgp，
							// alert(localData.substring(0,40));
							// let base64Image = 'data:image/jpeg;base64,' + localData;
							formData.append('file', that.localData);
							that.dataService.uploadFileApp(formData).subscribe(member => {
								if (!member.DATA) {
									that.loading = false;
									that.appService.toastTip(member.MSG, false);
								} else {
									const data = member.DATA;
									that.downloadSingleFile(data.id).then(resOut => {
										that.loading = false;
										let fieldIds = '';
										that.files.forEach((element, index) => {
											if (index !== that.files.length - 1) {
												fieldIds += element.id + ',';
											} else {
												fieldIds += element.id;
											}
										});
										that.fileObjListChange.emit(fieldIds);
										that.changeDetectorRef.detectChanges();
									});
								}
							}, (err) => {
								that.loading = false;
								that.appService.toastTip('get_data_failed_key', true);
							});
							// andriod中localId可以作为img标签的src属性显示图片；
							// iOS应当使用 getLocalImgData 获取图片base64数据，从而用于img标签的显示（在img标签内使用 wx.chooseImage 的 localid 显示可能会不成功）
						}
					})
				}
			});
		});
		// });
	}

	viewFile(event, index, file) {
		if (file.type == '.pdf') {
			this.navController.navigateForward(['/preview-file'], {
				queryParams: {
					filePath: file.path,
					isPDF: false
				}
			});
		} else {
			window.open(file.path, '_self');
		}
		let ios = this.nativeService.isIos();
		if (this.isH5) { // 企业微信--安卓
			wx.ready(function () {
				alert('previewFile');
				wx.previewFile({
					url: file.path, // 需要预览文件的地址(必填，可以使用相对路径)
					name: file.originalName, // 需要预览文件的文件名，必须有带文件格式的后缀，例如.doc(不填的话取url的最后部分，最后部分是个包含格式后缀的文件名)
					size: file.size // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
				});
			})
		} else {
			  window.open(file.path, 'Download');
		}
	}
}
